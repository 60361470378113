  var rangeSliderTwo = $('#slider-range2');
       var rangeSliderAmountTwo = $('#amount2');
      var data 
      
if (localStorage.getItem('category_id') == null) {
   
    var x=null
    $.get( "http://devadmin.netcore.adwayit.in/api/products/getmaxpricebasedoncategory/0", function( d ) {
      
      
        if ($(rangeSliderTwo).length) {
            $(rangeSliderTwo).slider({
                range: true,
                min: 0,
                max:  d.maxprice,
                values: [  localStorage.getItem('lowprice'),localStorage.getItem('max_value') ],
                slide: function( event, ui ) {
                    $(rangeSliderAmountTwo).val( "Rs." + ui.values[ 0 ] + " - Rs." + ui.values[ 1 ] );
                }
            });
        }

        $(rangeSliderAmountTwo).val( "Rs." + $(rangeSliderTwo).slider( "values", 0 ) +
            " - Rs." + $(rangeSliderTwo).slider( "values", 1 ) );
      });
 
 
}
      else{
        $.get( "http://devadmin.netcore.adwayit.in/api/products/getmaxpricebasedoncategory/"+localStorage.getItem('category_id'), function( d ) {
       
   
            if ($(rangeSliderTwo).length) {
                $(rangeSliderTwo).slider({
                    range: true,
                    min: 0,
                    max: d.maxprice,
                    values: [ localStorage.getItem('lowprice'),localStorage.getItem('max_value') ],
                    slide: function( event, ui ) {
                        $(rangeSliderAmountTwo).val( "Rs." + ui.values[ 0 ] + " - Rs." + ui.values[ 1 ] );
                    }
                });
            }
    
            $(rangeSliderAmountTwo).val( "Rs." + $(rangeSliderTwo).slider( "values", 0 ) +
                " - Rs." + $(rangeSliderTwo).slider( "values", 1 ) );
          });
     
     
      }
 /*======= ui price range slider 2 ========*/
      

