var bootstrapSelectMenu = $('.select-contain-select');


  /*==== Bootstrap select picker =====*/
  if ($(bootstrapSelectMenu).length) {
    $(bootstrapSelectMenu).selectpicker({
        liveSearch: true,
        liveSearchPlaceholder: 'Search',
        liveSearchStyle: 'contains',
        size: 5
    });
}